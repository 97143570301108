import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PlaylistsComponent from './Playlists'

const styles = theme => ({
  middle: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(26, 35, 38, 1)',
      zIndex: 999
  }
});

class MiddleComponent extends React.Component {
  chatEnd = React.createRef();

  constructor(props) {
    super(props);
  }

  render() {
    const { classes, playlists, submitVideo } = this.props;
    return (
      <div className={classes.middle}>
        <PlaylistsComponent playlists={playlists} submitVideo={submitVideo}/>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MiddleComponent);
