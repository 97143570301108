import { combineReducers } from 'redux';
import roomdataReducer from './roomdataReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  roomdataReducer,
  userReducer,
});

export default rootReducer;
