import React from 'react';
import ReactPlayer from 'react-player';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  video_container: {
    width: '100%',
    height: '100%',
  },
});

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoid: '',
      start: 0,
    };
  }

  componentWillReceiveProps(props) {
    //console.log(props.roomData.video.current);
    if (props.roomData.video.current === -1) {
      this.setState({ start: 1 });
    } else {
      this.setState({ start: props.roomData.video.current });
    }

    this.setState({ videoid: props.roomData.video.id });
  }

  onEndede = () => {
    console.log('onended');
    this.setState({ start: 0 });
  };

  onPlaya = () => {
    console.log('playa');
    console.log(this.state.start);
  };
  onStarta = () => {
    console.log('starta');
    console.log(this.state.start);
  };

  render() {
    const { roomData, classes } = this.props;

    return (
      <div className={classes.video_container}>
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${this.state.videoid}`}
          playing={true}
          width="100%"
          height="100%"
          volume={0.1}
          onEnded={this.onEndede}
          onStart={this.onStarta}
          onPlay={this.onPlaya}
          config={{
            youtube: {
              playerVars: {
                start: this.state.start,
                autoplay: 1,
                controls: 1,
                showinfo: 1,
                annotations: 0,
                rel: 0,
                volume: 1,
                wmode: 'opaque',
                preload: true,
              },
            },
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(VideoPlayer);
