/* eslint-disable better/no-switches */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function roomdataReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ROOMDATA_SUCCESS:
      return {
        ...initialState,
        roomdata: action.data,
      };
    default:
      return state;
  }
}
