import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import './Chat.css';
import { Input } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  chat_full_container: {
    height: '100%',
    background: '#0A0A0A',
  },
  chat_container: {
    overflowY: 'auto',
    height: '95%',
  },
  chat_input: {
    height: '5%',
  },
});

class ChatComponent extends React.Component {
  chatEnd = React.createRef();

  constructor(props) {
    super(props);

    this.formatDate = this.formatDate.bind(this);
  }

  submitMessage = () => {
    let message = this.message.value;
    this.message.value = '';

    this.props.sendChat(message);
  };

  formatDate(time) {
    let date = new Date(time);
    let localeSpecificTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return localeSpecificTime.replace(/:\d+ /, ' ');
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.chatEnd.current.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const { chatProp, classes } = this.props;

    return (
      <div className={classes.chat_full_container}>
        <div className={classes.chat_container}>
          {chatProp.map((response, index) => {
            return (
              <div key={index}>
                <span className={`chat_rank ${response.rank}`}>
                  {response.rank.charAt(0)}
                </span>
                <span className="chat_user">{response.who}</span>
                <span className="chat_time">
                  {this.formatDate(response.time)}
                </span>
                <span className="chat_message">{response.msg}</span>
              </div>
            );
          })}
          <div ref={this.chatEnd} />
        </div>
        <div className={classes.chat_input}>
          <Input
            placeholder="Message"
            fullWidth
            inputRef={x => (this.message = x)}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                this.submitMessage();
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChatComponent);
