import playerApi from '../api/playerAPI';
import * as types from './actionTypes';

function beginLogin() {
  return { type: types.BEGIN_LOGIN_USER };
}

function loginSuccess(data) {
  return {
    type: types.LOGIN_SUCCESS_USER,
    data,
  };
}

export function loadUserPlaylistsSuccess(data) {
  return { type: types.GET_USER_PLAYLISTS_SUCCESS, data };
}

function loginError() {
  return { type: types.LOGIN_ERROR_USER };
}

export function userLogin(data) {
  console.log(data);
  return dispatch => {
    dispatch(beginLogin());

    return playerApi
      .userLogin(data)
      .then(response => {
        if (response.success) {
          dispatch(loginSuccess(data));
        } else {
          dispatch(loginError());
        }
      })
      .catch(function(response) {
        console.log(response);
        if (response instanceof Error) {
          console.log('Error', response.message);
        }
      });
  };
}

export function userData() {
  return function(dispatch) {
    return playerApi
      .getUserData()
      .then(data => {
        dispatch({ type: types.GET_USER_DATA_SUCCESS, data });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadUserPlaylists() {
  return function(dispatch) {
    return playerApi.getUserPlaylists().then(data => {
      dispatch(loadUserPlaylistsSuccess(data));
    }).catch(error => {
      throw (error);
    });
  };
}