export const LOAD_ROOMDATA_SUCCESS = 'LOAD_ROOMDATA_SUCCESS';

export const BEGIN_LOGIN_USER = 'BEGIN_LOGIN_USER';
export const LOGIN_SUCCESS_USER = 'LOGIN_SUCCESS_USER';
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER';

export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const GET_USER_PLAYLISTS_REQUEST = 'GET_USER_PLAYLISTS_REQUEST'
export const GET_USER_PLAYLISTS_SUCCESS = 'GET_USER_PLAYLISTS_SUCCESS'
export const GET_USER_PLAYLISTS_FAILURE = 'GET_USER_PLAYLISTS_FAILURE'

export const SUBMIT_VIDEO_SUCCESS = 'SUBMIT_VIDEO_SUCCESS';
export const SUBMIT_VIDEO_FAILURE = 'SUBMIT_VIDEO_FAILURE';