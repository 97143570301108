import React from 'react';
import {
  Grid,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  Slide,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import socketIOClient from 'socket.io-client';
import { connect } from 'react-redux';

import VideoPlayer from './VideoPlayer/VideoPlayer';
import ChatComponent from './Chat/Chat';
import MiddleComponent from './Middle/Middle';

import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PlayListIcon from '@material-ui/icons/QueueMusic';
import QueueIcon from '@material-ui/icons/Queue';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HistoryIcon from '@material-ui/icons/History';
import RQueueIcon from '@material-ui/icons/PlaylistPlay';
import LogInIcon from '@material-ui/icons/Rowing';

import { userLogin, userData, loadUserPlaylists } from '../../actions/user';
import { loadRoomdata } from '../../actions/roomdata';
import { submitVideo } from "../../actions/room";

import Cookies from 'js-cookie';

const mapStateToProps = state => ({
  loggedIn: state.userReducer.loggedIn,
  userData: state.userReducer.userData,
  userPlaylists: state.userReducer.userPlaylists,
  roomData: state.roomdataReducer.roomdata,
});
const mapDispatchToProps = dispatch => ({
  loginUser: data => dispatch(userLogin(data)),
  getUserData: () => dispatch(userData()),
  getRoomData: () => dispatch(loadRoomdata()),
  getUserPlaylists: () => dispatch(loadUserPlaylists()),
  submitVideo: (data) => dispatch(submitVideo(data))
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  main_color: {
    background: '#0e1314',
  },
  clear_bg: {
    background: 'transparant',
  },
  top_paper: {
    height: '6vh',
  },
  mid_paper: {
    height: '88vh',
    position: 'relative'
  },
  mid_chat_paper: {
    height: '88vh',
  },
  bottom_paper: {
    height: '6vh',
  },
  mid_container: {
    position: 'absolute'
  }
});

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false,
  };

  constructor(props) {
    super(props);

    let _this = this;

    this.state = {
      chatEntrys: [],
      dialogLoginState: false,
      socketConnected: false,
      middleContainerVisible: false,
    };

    this.socket = socketIOClient('https://player.evilwhale.nl', {
      query: '&slug=developer-room',
      forceNew: true,
    });

    this.socket.on('welcome', function() {
      console.log('Socket connected');
      _this.setState({ socketConnected: true });

      _this.props.getUserData().then(() => {
        if (_this.props.userData && _this.state.socketConnected) {
          _this.socket.emit(
            'join',
            _this.props.userData.stats.username,
            _this.props.userData._id
          );
          _this.props.getUserPlaylists()
        } else if (_this.state.socketConnected) {
          _this.socket.emit('join', 'guest', 'guest');
        }
      });
    });

    this.props.getRoomData();

    this.sendChat = this.sendChat.bind(this);
    this.socket.on('chat', function(who, msg, time, rank) {
      console.log('received chat message')
      addChatMessage(who, msg, time, rank);
    });

    const addChatMessage = (who, msg, time, rank) => {
      let chatEntry = {
        who: who,
        msg: msg,
        time: time,
        rank: rank,
      };
      this.setState({ chatEntrys: [...this.state.chatEntrys, chatEntry] });
    };
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  toggleLoginDialogOpen = () => {
    this.setState({ dialogLoginState: true });
  };

  toggleLoginDialogClose = () => {
    this.setState({ dialogLoginState: false });
  };

  loginAction = () => {
    let email = this.email.value;
    let password = this.password.value;

    this.props.loginUser({ email, password });
  };

  sendChat(message) {
    let chatEntry = {
      message: message,
      username: this.props.userData.stats.username,
      usernameid: this.props.userData._id,
    };
    console.log(chatEntry);
    this.socket.emit('send', chatEntry);
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.props.getRoomData();
    }, 2000);
  }

  toggleMiddleVisability() {
    this.setState(prevState => ({
      middleContainerVisible: !prevState.middleContainerVisible
    }))
  }

  // TODO: Badge in header on icon
  render() {
    const { videoUrl } = this.state;
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper
              square={true}
              className={`${classes.top_paper} ${classes.main_color}`}
            >
              <Grid item xs={3}>
                <BottomNavigation
                  showLabels
                  className={`${classes.main_color}`}
                >
                  <BottomNavigationAction
                    label="Dashboard"
                    icon={<DashboardIcon />}
                  />
                  <BottomNavigationAction
                    label="Settings"
                    icon={<SettingsIcon />}
                  />
                  <BottomNavigationAction
                    label="History"
                    icon={<HistoryIcon />}
                  />
                  <BottomNavigationAction label="Queue" icon={<RQueueIcon />} />
                </BottomNavigation>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={10}>
            <Paper square={true} className={classes.mid_paper}>
              {this.state.middleContainerVisible && (
                <MiddleComponent playlists={this.props.userPlaylists} submitVideo={this.props.submitVideo}/>
              )}
              <VideoPlayer roomData={this.props.roomData} />
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper square={true} className={classes.mid_chat_paper}>
              <ChatComponent
                chatProp={this.state.chatEntrys}
                sendChat={this.sendChat}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper square={true} className={`${classes.bottom_paper}`}>
              {this.props.loggedIn ? (
                <BottomNavigation
                  showLabels
                  className={`${classes.main_color} ${classes.bottom_paper}`}
                >
                  <BottomNavigationAction
                    label="Add Video"
                    icon={<QueueIcon />}
                    onClick={() => this.toggleMiddleVisability()}
                  />
                  <BottomNavigationAction
                    label="Playlists"
                    icon={<PlayListIcon />}
                  />
                  <BottomNavigationAction
                    label="Favorites"
                    icon={<FavoriteIcon />}
                  />
                </BottomNavigation>
              ) : (
                <BottomNavigation
                  showLabels
                  className={`${classes.main_color} ${classes.bottom_paper}`}
                >
                  <BottomNavigationAction
                    label="Log In"
                    icon={<LogInIcon />}
                    onClick={this.toggleLoginDialogOpen}
                  />
                  />
                </BottomNavigation>
              )}
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.dialogLoginState}
          onClose={this.toggleLoginDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Login</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              inputRef={x => (this.email = x)}
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
            />

            <TextField
              inputRef={x => (this.password = x)}
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleLoginDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.loginAction} color="primary">
              Login
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResponsiveDrawer)
);
