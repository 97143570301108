/* eslint-disable better/no-switches */
import initialState from './initialState';
import Cookies from 'js-cookie';

import {
  BEGIN_LOGIN_USER,
  LOGIN_SUCCESS_USER,
  LOGIN_ERROR_USER,
  GET_USER_DATA_SUCCESS,
  GET_USER_PLAYLISTS_SUCCESS,
} from '../actions/actionTypes';

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_LOGIN_USER:
      return Object.assign({}, state, { isWaiting: true });
    case LOGIN_SUCCESS_USER:
      console.log('indereducer');
      Cookies.set('authenticated', 'ja', { expires: 60 });
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: true,
        loggedIn: true,
      });
    case LOGIN_ERROR_USER:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: false,
      });
    case GET_USER_DATA_SUCCESS:
      console.log(action);
      return { ...state, userData: action.data.data };
    case GET_USER_PLAYLISTS_SUCCESS:
      console.log(action)
      return {...state, userPlaylists: action.data.playlists }
    default:
      return state;
  }
};

export default userReducer;
