import playerApi from '../api/playerAPI';
import * as types from './actionTypes';

export function submitVideoSuccess(data) {
  return { type: types.SUBMIT_VIDEO_SUCCESS, data };
}

export function submitVideo(data) {
  console.log(data)
  return function(dispatch) {
    return playerApi.postAddToQueue(data).then(data => {
      console.log(data)
      dispatch(submitVideoSuccess(data));
    }).catch(error => {
      console.log(error)
      throw (error);
    });
  };
}
