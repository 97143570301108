import playerApi from '../api/playerAPI';
import * as types from './actionTypes';

export function loadRoomdataSuccess(data) {
  return { type: types.LOAD_ROOMDATA_SUCCESS, data };
}

export function loadRoomdata() {
  return function(dispatch) {
    return playerApi.getRoomdata().then(data => {
      dispatch(loadRoomdataSuccess(data));
    }).catch(error => {
      throw (error);
    });
  };
}
