import Cookies from 'js-cookie';
console.log(Cookies.get());
export default {
  roomdata: {
    room: 'Developer Room',
    slug: 'developer-room',
    creator: 'Jowan',
    cycle: true,
    ranks: {
      room_mod: [],
      room_admin: [
        {
          name: 'gevalo1',
        },
      ],
    },
    statistics: {
      seconds_played: 5547662,
      songs_played: 17385,
    },
    waitlist: {
      waitlist_status: 'unlocked',
      waitlist_names: [],
      waitlist_total: 0,
    },
    users: {
      online_names: [],
      online_guests: 0,
      online_total: 0,
    },
    video: {
      live: false,
      thumb: 'nothumb',
      songid: 'Ru8t1rrD2b',
      paused: false,
      replay: 0,
      title: null,
      dj: null,
      current: -1,
      duration: -1,
      url: 'https://www.youtube.com/watch?v=novideo',
      id: 'novideo',
    },
    public: true,
  },
  isWaiting: false,
  authenticated: false,
  loggedIn: typeof Cookies.get('authenticated') !== 'undefined',
  userData: {},
  userPlaylists: []
};
