class PlayerApi {
  static getRoomdata() {
    return fetch('https://player.evilwhale.nl/api/room/data/developer-room')
      .then(response => {
        return response.json();
      })
      .catch(error => {
        return error;
      });
  }
  static getUserData() {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    return fetch('https://player.evilwhale.nl/api/user/data', requestOptions)
      .then(response => {
        return response.json();
      })
      .catch(error => {
        return error;
      });
  }
  static userLogin(data) {
    console.log(data);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(data),
    };

    return fetch('https://player.evilwhale.nl/login', requestOptions)
      .then(response => {
        console.log(response);
        return { success: true };
      })
      .catch(error => {
        return error;
      });
  }
  static getUserPlaylists() {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    return fetch('https://player.evilwhale.nl/api/user/playlists', requestOptions)
      .then(response => {
        return response.json();
      })
      .catch(error => {
        return error;
      });
  }
  static postAddToQueue(data) {
    console.log(data)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(data),
    };

    return fetch('https://player.evilwhale.nl/api/room/join', requestOptions)
      .then(response => {
        console.log(response);
        return { success: true };
      })
      .catch(error => {
        return error;
      });
  }
}
export default PlayerApi;
