import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Divider } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  wrapper: {
      height: '100%',
      backgroundColor: '#12181a'
  }
});

class PlaylistsComponent extends React.Component {
  chatEnd = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
        activePlaylistIndex: 0
    }
  }

  clickPlaylist (index) {
      this.setState({activePlaylistIndex: index})
  }

  submitPlaylist () {
      let playlistID = this.props.playlists[this.state.activePlaylistIndex].id
      // for submit we need:
      // _slug (can be empty)
      // id
      // false if playlist
      this.props.submitVideo({'slug': 'uselesslol', 'url': playlistID, 'nm': false})
  }

  render() {
    const { classes, playlists } = this.props;
    console.log(playlists)
    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
            <Grid item xs={1}>
                <Paper square={true} className={classes.wrapper}>
                    {playlists.map((row, index) => (
                        <div onClick={() => this.clickPlaylist(index)}>{row.name}</div>
                    ))}
                </Paper>
            </Grid>
            <Grid item xs={11} style={{maxHeight: '100%', overflow: 'auto'}}>
                <Paper square={true} className={classes.wrapper} >
                    <Grid container spacing={0}>
                        <Grid item xs={1}>Songs: {playlists[this.state.activePlaylistIndex].songs.length}</Grid>
                        <Grid item xs={1}>Total duration</Grid>
                        <Grid item xs={1} onClick={() => this.submitPlaylist()}>Add to queue</Grid>
                        <Grid item xs={1}>Rename</Grid>
                        <Grid item xs={1}>Remove</Grid>
                    </Grid>
                    {playlists[this.state.activePlaylistIndex].songs.map((row, index) => (
                        <div>{row.title}</div>
                    ))}
                </Paper>
            </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PlaylistsComponent);
