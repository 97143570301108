import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import reduxStore from './store';

import Room20 from './components/Room2.0/Room';

import './index.css';

const store = reduxStore();
//     background-color: rgba(14, 19, 20, 1);
const GlobalTheme = createMuiTheme({
  palette: {
    primary: '#0e1314'[100],
    secondary: {
      main: '#0e1314',
    },
    type: 'dark',
  },
  typography: { useNextVariants: true },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={GlobalTheme}>
	<Room20/>
    </MuiThemeProvider>
  </Provider>,
  document.querySelector('#root')
);
